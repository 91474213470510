<template lang="html">
  <div class="row">
    <div class="col-lg-8 col-md-8 col-sm-12">
      <div class="card">
        <div class="card-body form-card">

          <div class="form-group row" v-if="form.source == 'strava'">
            <div class="col-2">
              <label>Strava ID</label>
            </div>
            <div class="col-10">
              <a :href="'https://www.strava.com/activities/' + form.detail_id" target="_blank">{{ form.detail_id }}</a>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-2">
              <label>User ID</label>
            </div>
            <div class="col-10">
              <router-link :to="'/users/' + form.user_id">{{ form.user_id }}</router-link>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-2">
              <label>Tiêu đề</label>
            </div>
            <div class="col-10">
              <el-input placeholder="Tiêu đề" v-model="form.name" :disabled="true"></el-input>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-2">
              <label>Loại hoạt động</label>
            </div>
            <div class="col-10">
              <el-select class="full-width" v-model="form.irace_type">
                <el-option v-for="(item, index) in types" :key="index" :label="item" :value="item"></el-option>
              </el-select>
            </div>
          </div>

           <div class="form-group row">
            <div class="col-2">
              <label>Trạng thái</label>
            </div>
            <div class="col-10">
              <el-select class="full-width" v-model="form.visibility">
                <el-option v-for="(item, index) in visibilities" :key="index" :label="item" :value="item"></el-option>
              </el-select>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-2">
              <label>Ngày thực hiện</label>
            </div>
            <div class="col-10">
              <el-input v-model="form.start_date_local"></el-input>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-2">
              <label>Quãng đường</label>
            </div>
            <div class="col-10">
              <el-input type="number" v-model="form.distance">
                <template slot="append">m</template>
              </el-input>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-2">
              <label>Thời gian</label>
            </div>
            <div class="col-10">
              <el-input type="number" v-model="form.moving_time">
                <template slot="append">s</template>
              </el-input>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-2">
              <label>Pace</label>
            </div>
            <div class="col-10">
              <el-input type="number" v-model="form.pace" :disabled="true">
                <template slot="append">min/km</template>
              </el-input>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="col-lg-4 col-md-4 col-sm-12 sidebar-right">
      <div class="card">
        <div class="card-body form-card">

          <div class="form-group">
            <label>Tạo thủ công</label>
            <el-switch
              class="pull-right"
              v-model="form.manual"
              :active-value="1"
              :inactive-value="0">
            </el-switch>
          </div>

          <div class="form-group">
            <label>GPS</label>
            <el-switch
              disabled
              class="pull-right"
              v-model="have_gps"
              :active-value="1"
              :inactive-value="0">
            </el-switch>
          </div>

          <div class="form-group">
            <label>Accepted tag</label>
            <el-switch
              class="pull-right"
              v-model="form.from_accepted_tag"
              :active-value="1"
              :inactive-value="0">
            </el-switch>
          </div>

          <div class="form-group">
            <label>Đã xóa</label>
            <el-switch
              class="pull-right"
              v-model="form.is_deleted"
              :active-value="1"
              :inactive-value="0">
            </el-switch>
          </div>

          <div class="form-group">
            <label>Ngày tạo</label>
            <span class="pull-right">{{ $util.dateTimeFormat(form.created_at) }}</span>
          </div>

          <div class="form-group">
            <label>Ngày cập nhật</label>
            <span class="pull-right">{{ $util.dateTimeFormat(form.updated_at) }}</span>
          </div>

        </div>
      </div>
    </div>

    <div class="container-fluid">
      <div class="page-action">
        <el-button @click="cancel">Quay lại</el-button>
        <el-button type="primary" @click="save">Cập nhật</el-button>
      </div>
    </div>
    <loading :active.sync="loading"></loading>
  </div>
</template>

<script>

import { Select, Option, Input, Button, Switch, Message } from 'element-ui';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  components: {
    ElInput: Input,
    ElButton: Button,
    ElSwitch: Switch,
    ElSelect: Select,
    ElOption: Option,
    Message,
    Loading
  },

  data() {
    return {
      loading: false,
      have_gps: 0,
      form: {},
      types: ['Run', 'Ride', 'Swim', 'Workout'],
      visibilities: ['everyone', 'followers_only', 'only_me', 'private']
    }
  },

  computed: {
    originalForm() {
      return this.$store.state.activityDetail;
    }
  },

  mounted() {
    this.$store.dispatch('setPageTitle', 'Cập nhật hoạt động');
    this.$store.dispatch('setCurrentActions', [{
      label: 'Cập nhật',
      type: 'primary',
      icon: '',
      callback: this.save
    }])

    let detail_id = this.$route.params.id;
    this.$store.dispatch('fetchActivityDetail', detail_id);
  },

  methods: {
    cancel() {
      this.$router.push({name: 'Run'});
    },

    save() {
      this.loading = true;
      this.$store.dispatch('updateActivityDetail', this.form).then(res => {
        this.loading = false;
        if (res.success) {
          Message({
            message: 'Cập nhật thành công',
            type: 'success'
          });
        } else {
          Message({
            message: res.message,
            type: 'error'
          });
        }
      });
    },

    calcPace() {
      var pace = 0;
      if (!this.form.distance || !this.form.moving_time) {
        return this.form.pace = 0;
      }
      if (this.form.distance && this.form.moving_time) {
        pace = this.form.moving_time / 60 / (this.form.distance / 1000);
        pace = Math.round(pace * 100) / 100;
      }
      this.form.pace = pace;
    }
  },

  watch: {
    originalForm(newVal) {
      this.form = Object.assign({}, this.form, newVal);
      this.have_gps = this.form.map ? 1 : 0;
    },

    'form.distance': function(newVal) {
      this.calcPace();
    },

    'form.moving_time': function(newVal) {
      this.calcPace();
    }

  },

  destroyed(){
    this.$store.dispatch('setCurrentActions', []);
    this.form = {};
    this.$store.state.couponDetail = [];
  }
}
</script>
