var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c("div", { staticClass: "col-lg-8 col-md-8 col-sm-12" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-body form-card" }, [
            _vm.form.source == "strava"
              ? _c("div", { staticClass: "form-group row" }, [
                  _vm._m(0),
                  _c("div", { staticClass: "col-10" }, [
                    _c(
                      "a",
                      {
                        attrs: {
                          href:
                            "https://www.strava.com/activities/" +
                            _vm.form.detail_id,
                          target: "_blank",
                        },
                      },
                      [_vm._v(_vm._s(_vm.form.detail_id))]
                    ),
                  ]),
                ])
              : _vm._e(),
            _c("div", { staticClass: "form-group row" }, [
              _vm._m(1),
              _c(
                "div",
                { staticClass: "col-10" },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/users/" + _vm.form.user_id } },
                    [_vm._v(_vm._s(_vm.form.user_id))]
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "form-group row" }, [
              _vm._m(2),
              _c(
                "div",
                { staticClass: "col-10" },
                [
                  _c("el-input", {
                    attrs: { placeholder: "Tiêu đề", disabled: true },
                    model: {
                      value: _vm.form.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "form-group row" }, [
              _vm._m(3),
              _c(
                "div",
                { staticClass: "col-10" },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "full-width",
                      model: {
                        value: _vm.form.irace_type,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "irace_type", $$v)
                        },
                        expression: "form.irace_type",
                      },
                    },
                    _vm._l(_vm.types, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item, value: item },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "form-group row" }, [
              _vm._m(4),
              _c(
                "div",
                { staticClass: "col-10" },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "full-width",
                      model: {
                        value: _vm.form.visibility,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "visibility", $$v)
                        },
                        expression: "form.visibility",
                      },
                    },
                    _vm._l(_vm.visibilities, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item, value: item },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "form-group row" }, [
              _vm._m(5),
              _c(
                "div",
                { staticClass: "col-10" },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.start_date_local,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "start_date_local", $$v)
                      },
                      expression: "form.start_date_local",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "form-group row" }, [
              _vm._m(6),
              _c(
                "div",
                { staticClass: "col-10" },
                [
                  _c(
                    "el-input",
                    {
                      attrs: { type: "number" },
                      model: {
                        value: _vm.form.distance,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "distance", $$v)
                        },
                        expression: "form.distance",
                      },
                    },
                    [_c("template", { slot: "append" }, [_vm._v("m")])],
                    2
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "form-group row" }, [
              _vm._m(7),
              _c(
                "div",
                { staticClass: "col-10" },
                [
                  _c(
                    "el-input",
                    {
                      attrs: { type: "number" },
                      model: {
                        value: _vm.form.moving_time,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "moving_time", $$v)
                        },
                        expression: "form.moving_time",
                      },
                    },
                    [_c("template", { slot: "append" }, [_vm._v("s")])],
                    2
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "form-group row" }, [
              _vm._m(8),
              _c(
                "div",
                { staticClass: "col-10" },
                [
                  _c(
                    "el-input",
                    {
                      attrs: { type: "number", disabled: true },
                      model: {
                        value: _vm.form.pace,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "pace", $$v)
                        },
                        expression: "form.pace",
                      },
                    },
                    [_c("template", { slot: "append" }, [_vm._v("min/km")])],
                    2
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "col-lg-4 col-md-4 col-sm-12 sidebar-right" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-body form-card" }, [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", [_vm._v("Tạo thủ công")]),
                _c("el-switch", {
                  staticClass: "pull-right",
                  attrs: { "active-value": 1, "inactive-value": 0 },
                  model: {
                    value: _vm.form.manual,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "manual", $$v)
                    },
                    expression: "form.manual",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", [_vm._v("GPS")]),
                _c("el-switch", {
                  staticClass: "pull-right",
                  attrs: {
                    disabled: "",
                    "active-value": 1,
                    "inactive-value": 0,
                  },
                  model: {
                    value: _vm.have_gps,
                    callback: function ($$v) {
                      _vm.have_gps = $$v
                    },
                    expression: "have_gps",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", [_vm._v("Accepted tag")]),
                _c("el-switch", {
                  staticClass: "pull-right",
                  attrs: { "active-value": 1, "inactive-value": 0 },
                  model: {
                    value: _vm.form.from_accepted_tag,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "from_accepted_tag", $$v)
                    },
                    expression: "form.from_accepted_tag",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", [_vm._v("Đã xóa")]),
                _c("el-switch", {
                  staticClass: "pull-right",
                  attrs: { "active-value": 1, "inactive-value": 0 },
                  model: {
                    value: _vm.form.is_deleted,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "is_deleted", $$v)
                    },
                    expression: "form.is_deleted",
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "form-group" }, [
              _c("label", [_vm._v("Ngày tạo")]),
              _c("span", { staticClass: "pull-right" }, [
                _vm._v(_vm._s(_vm.$util.dateTimeFormat(_vm.form.created_at))),
              ]),
            ]),
            _c("div", { staticClass: "form-group" }, [
              _c("label", [_vm._v("Ngày cập nhật")]),
              _c("span", { staticClass: "pull-right" }, [
                _vm._v(_vm._s(_vm.$util.dateTimeFormat(_vm.form.updated_at))),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "container-fluid" }, [
        _c(
          "div",
          { staticClass: "page-action" },
          [
            _c("el-button", { on: { click: _vm.cancel } }, [
              _vm._v("Quay lại"),
            ]),
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.save } },
              [_vm._v("Cập nhật")]
            ),
          ],
          1
        ),
      ]),
      _c("loading", {
        attrs: { active: _vm.loading },
        on: {
          "update:active": function ($event) {
            _vm.loading = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-2" }, [
      _c("label", [_vm._v("Strava ID")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-2" }, [
      _c("label", [_vm._v("User ID")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-2" }, [
      _c("label", [_vm._v("Tiêu đề")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-2" }, [
      _c("label", [_vm._v("Loại hoạt động")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-2" }, [
      _c("label", [_vm._v("Trạng thái")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-2" }, [
      _c("label", [_vm._v("Ngày thực hiện")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-2" }, [
      _c("label", [_vm._v("Quãng đường")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-2" }, [
      _c("label", [_vm._v("Thời gian")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-2" }, [_c("label", [_vm._v("Pace")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }